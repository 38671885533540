const defaultPLZ = [
    { value: '6861 / Alberschwende', label: 'Alberschwende (6861)' },
    { value: '6844 / Altach', label: 'Altach (6844)' },
    { value: '6866 / Andelsbuch', label: 'Andelsbuch (6866)' },
    { value: '6883 / Au', label: 'Au (6883)' },
    { value: '6781 / Bartholomäberg', label: 'Bartholomäberg (6781)' },
    { value: '6870 / Bezau', label: 'Bezau (6870)' },
    { value: '6874 / Bezau', label: 'Bezau (6874)' },
    { value: '6858 / Bildstein', label: 'Bildstein (6858)' },
    { value: '6723 / Blons', label: 'Blons (6723)' },
    { value: '6700 / Bludenz', label: 'Bludenz (6700)' },
    { value: '6719 / Bludesch', label: 'Bludesch (6719)' },
    { value: '6708 / Brand', label: 'Brand (6708)' },
    { value: '6900 / Bregenz', label: 'Bregenz (6900)' },
    { value: '6960 / Buch (Vorarlberg)', label: 'Buch (6960)' },
    { value: '6706 / Bürs', label: 'Bürs (6706)' },
    { value: '6707 / Bürserberg', label: 'Bürserberg (6707)' },
    { value: '6752 / Dalaas', label: 'Dalaas (6752)' },
    { value: '6884 / Damüls', label: 'Damüls (6884)' },
    { value: '6933 / Doren', label: 'Doren (6933)' },
    { value: '6850 / Dornbirn', label: 'Dornbirn (6850)' },
    { value: '6822 / Düns', label: 'Düns (6822)' },
    { value: '6822 / Dünserberg', label: 'Dünserberg (6822)' },
    { value: '6863 / Egg', label: 'Egg (6863)' },
    { value: '6911 / Eichenberg', label: 'Eichenberg (6911)' },
    { value: '6800 / Feldkirch', label: 'Feldkirch (6800)' },
    { value: '6733 / Fontanella', label: 'Fontanella (6733)' },
    { value: '6820 / Frastanz', label: 'Frastanz (6820)' },
    { value: '6833 / Fraxern', label: 'Fraxern (6833)' },
    { value: '6972 / Fußach', label: 'Fußach (6972)' },
    { value: '6974 / Gaißau', label: 'Gaißau (6974)' },
    { value: '6793 / Gaschurn', label: 'Gaschurn (6793)' },
    { value: '6811 / Göfis', label: 'Göfis (6811)' },
    { value: '6840 / Götzis', label: 'Götzis (6840)' },
    { value: '6971 / Hard', label: 'Hard (6971)' },
    { value: '6952 / Hittisau', label: 'Hittisau (6952)' },
    { value: '6973 / Höchst', label: 'Höchst (6973)' },
    { value: '6845 / Hohenems', label: 'Hohenems (6845)' },
    { value: '6914 / Hohenweiler', label: 'Hohenweiler (6914)' },
    { value: '6912 / Hörbranz', label: 'Hörbranz (6912)' },
    { value: '6751 / Innerbraz', label: 'Innerbraz (6751)' },
    { value: '6921 / Kennelbach', label: 'Kennelbach (6921)' },
    { value: '6833 / Klaus (Vorarlberg)', label: 'Klaus (6833)' },
    { value: '6754 / Klösterle', label: 'Klösterle (6754)' },
    { value: '6842 / Koblach', label: 'Koblach (6842)' },
    { value: '6942 / Krumbach (Vorarlberg)', label: 'Krumbach (6942)' },
    { value: '6932 / Langen bei Bregenz', label: 'Langen bei Bregenz (6932)' },
    { value: '6941 / Langenegg', label: 'Langenegg (6941)' },
    { value: '6830 / Laterns', label: 'Laterns (6830)' },
    { value: '6923 / Lauterach', label: 'Lauterach (6923)' },
    { value: '6764 / Lech', label: 'Lech (6764)' },
    { value: '6951 / Lingenau', label: 'Lingenau (6951)' },
    { value: '6911 / Lochau', label: 'Lochau (6911)' },
    { value: '6700 / Lorüns', label: 'Lorüns (6700)' },
    { value: '6713 / Ludesch', label: 'Ludesch (6713)' },
    { value: '6890 / Lustenau', label: 'Lustenau (6890)' },
    { value: '6841 / Mäder', label: 'Mäder (6841)' },
    { value: '6812 / Meiningen', label: 'Meiningen (6812)' },
    { value: '6881 / Mellau', label: 'Mellau (6881)' },
    { value: '6991 / Mittelberg', label: 'Mittelberg (6991)' },
    { value: '6900 / Möggers', label: 'Möggers (6900)' },
    { value: '6710 / Nenzing', label: 'Nenzing (6710)' },
    { value: '6714 / Nüziders', label: 'Nüziders (6714)' },
    { value: '6741 / Raggal', label: 'Raggal (6741)' },
    { value: '6830 / Rankweil', label: 'Rankweil (6830)' },
    { value: '6870 / Reuthe', label: 'Reuthe (6870)' },
    { value: '6943 / Riefensberg', label: 'Riefensberg (6943)' },
    { value: '6822 / Röns', label: 'Röns (6822)' },
    { value: '6832 / Röthis', label: 'Röthis (6832)' },
    { value: '6771 / Sankt Anton im Montafon', label: 'Sankt Anton im Montafon (6771)' },
    { value: '6771 / Sankt Gallenkirch', label: 'Sankt Gallenkirch (6771)' },
    { value: '6722 / Sankt Gerold', label: 'Sankt Gerold (6722)' },
    { value: '6822 / Satteins', label: 'Satteins (6822)' },
    { value: '6824 / Schlins', label: 'Schlins (6824)' },
    { value: '6881 / Schnepfau', label: 'Schnepfau (6881)' },
    { value: '6822 / Schnifis', label: 'Schnifis (6822)' },
    { value: '6886 / Schoppernau', label: 'Schoppernau (6886)' },
    { value: '6888 / Schröcken', label: 'Schröcken (6888)' },
    { value: '6780 / Schruns', label: 'Schruns (6780)' },
    { value: '6858 / Schwarzach (Vorarlberg)', label: 'Schwarzach (6858)' },
    { value: '6867 / Schwarzenberg (Vorarlberg)', label: 'Schwarzenberg (6867)' },
    { value: '6952 / Sibratsgfäll', label: 'Sibratsgfäll (6952)' },
    { value: '6782 / Silbertal', label: 'Silbertal (6782)' },
    { value: '6731 / Sonntag', label: 'Sonntag (6731)' },
    { value: '6700 / Stallehr', label: 'Stallehr (6700)' },
    { value: '6832 / Sulz (Vorarlberg)', label: 'Sulz (6832)' },
    { value: '6934 / Sulzberg', label: 'Sulzberg (6934)' },
    { value: '6712 / Thüringen', label: 'Thüringen (6712)' },
    { value: '6721 / Thüringerberg', label: 'Thüringerberg (6721)' },
    { value: '6774 / Tschagguns', label: 'Tschagguns (6774)' },
    { value: '6834 / Übersaxen', label: 'Übersaxen (6834)' },
    { value: '6773 / Vandans', label: 'Vandans (6773)' },
    { value: '6836 / Viktorsberg', label: 'Viktorsberg (6836)' },
    { value: '6767 / Warth (Vorarlberg)', label: 'Warth (6767)' },
    { value: '6837 / Weiler', label: 'Weiler (6837)' },
    { value: '6922 / Wolfurt', label: 'Wolfurt (6922)' },
    { value: '6835 / Zwischenwasser', label: 'Zwischenwasser (6835)' },
    { value: 'Österreich', label: 'Österreich (AUT)' },
    { value: 'Deutschland', label: 'Deutschland (DE)' },
    { value: 'Schweiz', label: 'Schweiz (CH)' },
    { value: 'Fürstentum Liechtenstein', label: 'Fürstentum Liechtenstein (FL)' }
]

export default defaultPLZ;
