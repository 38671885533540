import React, { Component } from 'react'
import './TitleHeaderGreen.css';
export class TitleHeaderGreen extends Component<{ title: string, subtitle: string }, {}> {
    render() {
        return (
            <div className="pagetitleheader-container-green">
                <div className="pagetitleheader-title-green">{this.props.title}</div>
                <div className="pagetitleheader-subtitle-green">{this.props.subtitle}</div>
            </div>
        );
    }
}